* {
  box-sizing: border-box;
}

body,
html {
  display: block;
  font-family: $fontFamily;
  font-weight: $fontWeightRegular;
  color: $textBaseColor;
  line-height: 1;
  margin: 0;
  background: $bodyBackgroundColor;
  background: var(--body-background);
  min-height: 100vh;
  scroll-behavior: smooth;
  font-variant-numeric: lining-nums;
  overflow-x: hidden;
  overflow-x: clip;

  @include fontSize($baseFontSize);

  &.no-scroll--burger {
    @media (max-width: $screen-laptop) {
      overflow: hidden;
      position: relative;
    }

    @media (min-width: $screen-desktop-sm) {
      .page-overlay--visible {
        display: none;
      }
    }
  }
}

button {
  font-family: $fontFamily;

  &:focus-visible {
    outline-offset: 4px;
  }
}

input,
select,
textarea {
  font-family: $fontFamily;
}

img {
  width: auto;
  height: auto;
}

/* stylelint-disable-next-line selector-type-no-unknown */
nobr {
  white-space: normal;
}

.visually-hidden:not(:focus) { // rule for old browsers, which can not handle :not([multiple selectors])
  @include visually-hidden();
}

.visually-hidden:not(:focus, :active) {
  @include visually-hidden();
}

*:focus:not(:focus-visible) { // stylelint-disable-line
  outline: none;
}

*:focus-visible {
  outline-color: $blackColor;
}
